import React, { useContext, PropsWithChildren } from 'react';
import { IntlProvider } from 'react-intl';

import AppContextPropsType from '../types/AppContextPropsType';
import { IntlGlobalProvider } from './Utils';
import AppContext from './AppContext';
import AppLocale from '../locale/AppLocale';

const LocaleProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const { locale } = useContext<AppContextPropsType>(AppContext);
    const currentAppLocale = AppLocale[locale.locale];

    return (
        <IntlProvider
            locale={currentAppLocale.locale}
            defaultLocale="ko"
            messages={currentAppLocale.messages}
        >
            <IntlGlobalProvider>{children}</IntlGlobalProvider>
        </IntlProvider>
    );
};

export default LocaleProvider;
