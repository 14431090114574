import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import './AppHeader.scss';

const AppHaeder = () => {

    /**
     * react router Hook
     */
    const histroy = useHistory();

    /**
     * 스크롤 위치
     */
    const [scrollDirection, setScrollDirection] = useState('');

    /**
     * 헤더 여부
     */
    const [isHeader, setIsHeader] = useState(true);

    /**
     * 미디어 매칭 여부를 반환한다.
     */
    const isMatchMedia = (): boolean => {
        return window.matchMedia('(max-width: 500px)').matches;
    }

    /**
     * 스크롤 핸들링 한다.
     */
    const handleScroll = () => {
        // 헤더 높이
        const headHeight = 82;
        const topLocation = window.pageYOffset || document.documentElement.scrollTop;
            if (headHeight <= topLocation) {
                if (scrollDirection === 'up') {
                    setIsHeader(true);
                } 
                else if (scrollDirection === 'down') {
                    setIsHeader(false);
                }
            } else if (headHeight > topLocation) {
                setIsHeader(true);
            }
    };

    /**
     * 휠을 핸들링 한다.
     * 
     * @param e 이벤트
     */
    const handelWheel = (e: any) => {
        let delta;
        if (e.wheelDelta) {
            delta = e.wheelDelta;
        } 
        else {
            delta = -1 * e.deltaY;
        }

        if (delta < 0) {
            setScrollDirection('down');
        } else if (delta > 0) {
            setScrollDirection('up');
        }
    };

    /**
     * 리액트 hook
     */
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        window.addEventListener('wheel', handelWheel);

        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('wheel', handelWheel);
        };
    }, [scrollDirection]);

    return (
        <>
            <header className={`header-container ${isHeader}`} >
                <div className={`header ${isHeader}`}>
                    <div className='top'>
                        {
                            isMatchMedia() ? (
                                <>
                                    <img
                                        className="logo"
                                        src="/assets/image/logo-500px.svg"
                                        alt="logo" onClick={(event) => {
                                            event.preventDefault();
                                            histroy.push('/about');
                                            window.scrollTo(0, 0);
                                        }} />
                                </>
                            )
                                : (
                                    <>
                                        <img
                                            className="logo"
                                            src="/assets/image/logo.svg"
                                            alt="logo" onClick={(event) => {
                                                event.preventDefault();
                                                histroy.push('/about');
                                                window.scrollTo(0, 0);
                                            }} />
                                    </>
                                )
                        }
                        <nav>
                            <div className="btn-grp">
                                <button
                                    className="btn-about"
                                    onClick={(event) => {
                                        event.preventDefault();
                                        histroy.push('/about');
                                    }}>
                                    ABOUT
                                </button>

                                <button
                                    className="btn-solutions"
                                    onClick={(event) => {
                                        event.preventDefault();
                                        histroy.push('/solutions');
                                    }}>
                                    SOLUTIONS
                                </button>

                                <a className="btn-contact" href="#contact">
                                    Contact Us
                                </a>
                            </div>
                        </nav>
                    </div>
                </div>
            </header>
        </>
    );
};

export default AppHaeder;
