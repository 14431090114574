import React, { PropsWithChildren, useReducer } from 'react';
import defaultConfig from '../../types/Config';
import AppContext from '../AppContext';
import { contextReducer, ThemeSetting } from './ContextReducer';

export const ContextState = {
    locale: defaultConfig.locale
};

const ContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const [state, dispatch] = useReducer(contextReducer, ContextState);

    const changeLocale = (locale: any) => {
        dispatch({
            type: ThemeSetting.CHANGE_LOCALE,
            payload: locale
        });
    };

    return (
        <AppContext.Provider
            value={{
                ...state,
                changeLocale
            }}
        >
            {children}
        </AppContext.Provider>
    );
};

export default ContextProvider;
