
const defaultConfig: any = {
    locale: {
        languageId: 'korean',
        locale: 'ko',
        name: '한국어',
        icon: 'ko',
    },
};

export default defaultConfig;