import React, { PropsWithChildren } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Redirect, Route, Switch } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import routes from '../routes';
import AppSuspense from '../suspense/AppSuspense';
import './ContentView.scss';

/**
 * css 효과 소품
 */
interface TransitionWrapperProps {
    children: any;
}

/**
 * css 효과
 *
 * @param props 소품
 */
const TransitionWrapper = (props: TransitionWrapperProps) => {
    /**
     * 소품
     */
    const { children } = props;

    return (
        <TransitionGroup appear enter exit className="transition">
            <CSSTransition
                key={uuidv4()}
                timeout={{
                    enter: 300,
                    exit: 300
                }}
                classNames="fade"
            >
                <div className="main-content-view">{children}</div>
            </CSSTransition>
        </TransitionGroup>
    );
};

/**
 * 컨텐츠
 */
const ContentView: React.FC<PropsWithChildren> = ({ children }) => {
    return (
        <TransitionWrapper>
            <AppSuspense>
                <Switch>
                    {routes.map((route, idx) => {
                        return (
                            <Route
                                key={idx}
                                exact
                                path={route.path}
                                component={route.component}
                            />
                        );
                    })}
                    <Redirect to="/about"></Redirect>
                </Switch>
                {children}
            </AppSuspense>
        </TransitionWrapper>
    );
};

export default ContentView;
