
export interface LanguageProps {
    languageId: string;
    locale: string;
    name: string;
    icon: string;
} 

export const languageData: Array<LanguageProps> = [
    {
        languageId: 'korean',
        locale: 'ko',
        name: '한국어',
        icon: 'korea',
    },
    {
        languageId: 'english',
        locale: 'en',
        name: 'English',
        icon: 'us',
    },
];

export default interface AppContextPropsType {
    locale: {
        languageId: string;
        locale: string;
        name: string;
        icon: string;
    };
    changeLocale: (locale: LanguageProps) => void;
}
