
export const ThemeSetting = {
    CHANGE_LOCALE: 'CHANGE_LOCALE',
};

export function contextReducer(state: any, action: any): any {
    switch (action.type) {
        case ThemeSetting.CHANGE_LOCALE: {
            return {
                ...state,
                locale: action.payload,
            };
        }
        default:
            return state;
    }
}
