import React, { useContext } from 'react';
import AppContextPropsType, { languageData } from '../../../core/types/AppContextPropsType';
import AppContext from '../../../core/utility/AppContext';
import './AppFooter.scss';

const AppFooter = () => {
    /**
     * 업데이트 유저
     */
    const { changeLocale } = useContext<AppContextPropsType>(AppContext);

    return (
        <>
            <footer className="footer-container">
                <div className="footer">
                    <div className='top'>
                        <div className='left'>
                            Join service
                        </div>

                        <div className='right'>
                            Get started &gt;
                        </div>
                    </div>

                    <div className='center'>
                        <div className='row'>
                            <div className='left'>
                                <div className='solutions'>
                                    Solutions
                                </div>

                                <div className='btn-grp'>
                                    <div className='btn'>
                                        SMART Report  /
                                    </div>

                                    <div className='btn'>
                                        SMART Performance MKT
                                    </div>
                                </div>
                            </div>

                            <div className='right'>
                                <div className='btn-grp'>
                                    <div
                                        className='btn'
                                        onClick={() => {
                                            changeLocale(languageData[0])
                                        }}>
                                        ko
                                    </div>
                                    |
                                    <div
                                        className='btn'
                                        onClick={() => {
                                            changeLocale(languageData[1])
                                        }}>
                                        eng
                                    </div>
                                </div>

                                <div className='privacy'>
                                    Privacy Policy
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='bottom'>
                        <div className='row'>
                            <div className='left'>
                                <div className='about'>
                                    About
                                </div>

                                <div className='btn-grp'>
                                    <div className='first-container'>
                                        <a className='first-btn' href="#missons">
                                            Missons &nbsp;
                                        </a>

                                        <a className='first-btn' href="#solution">
                                            Solution &nbsp;
                                        </a>
                                    </div>
                                    <div className='second-container'>
                                        <a className='second-btn' href="#clients">
                                            Clients & Partners &nbsp;
                                        </a>

                                        <a className='second-btn' href="#contact">
                                            Contact Us
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className='right'>
                                <div className='copy-right'>
                                    © 2021 AIVE Labs Corp. All Rights Reserved.
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </footer>
        </>
    );
};

export default AppFooter;
