import React from 'react';
import './AppLayout.scss';
import ContentView from './content/ContentView';
import AppFooter from './footer/AppFooter';
import AppHeader from './header/AppHeader';
import AppSidebar from './sidebar/AppSidebar';

const AppLayout = () => {

    return (
        <div className='root-container'>
            <AppHeader />
            {false && <AppSidebar />}
            <ContentView>
                <AppFooter />
            </ContentView>
        </div>
    );
};

export default React.memo(AppLayout);
