import React, { useLayoutEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import AppLayout from './pages/@layout/AppLayout';
import './App.scss';
import AOS from 'aos';
import 'aos/dist/aos.css';
import ContextProvider from './core/utility/ContextProvider';
import LocaleProvider from './core/utility/LocaleProvider';

const App = () => {
    /**
     * 리액트 훅
     */
    useLayoutEffect(() => {
        AOS.init({
            // startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
            // initClassName: 'aos-init', // class applied after initialization
            duration: 1200,
          
            // once: true, // whether animation should happen only once - while scrolling down
            // mirror: true, // whether elements should animate out while scrolling past them
        });
        AOS.refresh();
    }, []) 
    
    return (
        <ContextProvider>
            <LocaleProvider>
                <BrowserRouter>
                    <AppLayout />
                </BrowserRouter>
            </LocaleProvider>
        </ContextProvider>
    );
};

export default App;
